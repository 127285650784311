const toggle = () => {
	const body = document.querySelector('.respekt-chisel');
	const toggleBtn = document.querySelector('.c-toggle');
	if (toggleBtn && body) {
		toggleBtn.addEventListener('click', () => {
			body.classList.toggle('menu-active');
		});
	}
};
export default toggle;
