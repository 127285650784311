/*
  Project: Respekt
  Author: WebCrafters Studio
 */

import fullpage from 'fullpage.js';
import './modules/share-panel';
import wpmlLs from './modules/wpml-ls';
import partnersSlider from './modules/partners-slider';
import toggle from './modules/toggle';
import panels from './modules/panels';
import cPopup from './modules/popup';
import counter from './modules/counter';
import cursor from './modules/cursor';
import hero from './modules/hero';
import brands from './modules/brands';
import transparency from './modules/transparency';
import potential from './modules/potential';
import modal from './modules/modal';

document.addEventListener('DOMContentLoaded', () => {
	// set normal scroll
	const el = document.querySelectorAll('.js-set-normal-scroll section');
	if (el) el.forEach((elSe) => elSe.classList.remove('section'));

	const wpmlLinks = document.querySelectorAll('.wpml-ls-link');
	if (wpmlLinks) wpmlLinks.forEach((link) => link.setAttribute('target', '_blank'));

	fullpage('#fullpage', {
		autoScrolling: true,
		// scrollHorizontally: false,
		navigation: true,
		scrollOverflow: true,
		navigationPosition: 'right',
		normalScrollElements: '#asda',
		licenseKey: 'R4KB8-25Q98-H1P3H-LXBGH-KXTNN',
		responsiveWidth: 992,
		afterRender() {
			const width = window.outerWidth;
			wpmlLs(width);
		},
		afterResize(width) {
			wpmlLs(width);
		},
	});

	const limitline = document.querySelector('.js-limit-line');
	if (limitline) {
		limitline.onclick = (e) => {
			if (e.target.classList.contains('wpcf7-list-item-label')) {
				e.preventDefault();
				limitline.classList.add('active');
			}
		};
	}

	partnersSlider();
	brands();
	transparency();
	potential();

	toggle();
	panels();
	cPopup();
	counter();
	cursor();
	hero();

	modal();

	const compare = document.querySelectorAll('.js-compare');
	const parentSelector = document.querySelector('body');

	compare.forEach((element) => {
		element.onmouseenter = (event) => {
			const className = event.target.dataset.compare;
			if (!parentSelector.classList.contains('fp-responsive')) {
				parentSelector.classList.add(className);
			}
		};

		element.onmouseleave = (event) => {
			const className = event.target.dataset.compare;
			parentSelector.classList.remove(className);
		};
	});
});
