import Swiper, { Navigation } from 'swiper';

const transparency = () => {
	const transparencySliders = document.querySelector('.js-slider-transparency');
	if (transparencySliders) {
		// eslint-disable-next-line no-unused-vars
		const swiper = new Swiper(transparencySliders, {
			modules: [Navigation],
			slidesPerView: 1,
			centeredSlides: true,
			loop: true,
			spaceBetween: 19,
			navigation: {
				nextEl: '.s-transparency__arrow--next',
				prevEl: '.s-transparency__arrow--prev',
			},
			slideToClickedSlide: true,
			preloadImages: false,
			lazy: true,
			effect: 'slide',
			breakpoints: {
				992: {
					slidesPerView: 3,
				},
				768: {
					slidesPerView: 2,
					spaceBetween: 24,
				},
			},
		});
	}
};

export default transparency;
