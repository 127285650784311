const modal = () => {
	const modalBtn = document.querySelector('.s-brands__popup');
	if (modalBtn) {
		modalBtn.addEventListener('click', (e) => {
			e.preventDefault();
			const modalHtml = document.querySelector('.c-modal');
			if (modalHtml) {
				modalHtml.classList.add('c-modal--active');
			}
		});
	}

	const modalBtnMobile = document.querySelector('.s-brands__popup--mobile');
	if (modalBtnMobile) {
		modalBtnMobile.addEventListener('click', (e) => {
			e.preventDefault();
			const modalHtml = document.querySelector('.c-modal');
			if (modalHtml) {
				modalHtml.classList.add('c-modal--active');
			}
		});
	}

	const overlay = document.querySelector('.c-modal__overlay');
	if (overlay) {
		overlay.addEventListener('click', (e) => {
			e.preventDefault();
			const modalHtml = document.querySelector('.c-modal');
			if (modalHtml) {
				modalHtml.classList.remove('c-modal--active');
			}
		});
	}

	const close = document.querySelector('.c-modal__close');
	if (close) {
		close.addEventListener('click', (e) => {
			e.preventDefault();
			const modalHtml = document.querySelector('.c-modal');
			if (modalHtml) {
				modalHtml.classList.remove('c-modal--active');
			}
		});
	}
};
export default modal;
