const sharePanel = document.querySelectorAll('.js-share-panel-open');

if (sharePanel) {
	sharePanel.forEach((element) => {
		const el = element;
		el.onclick = (event) => {
			event.stopPropagation();

			if (event.target.classList.contains('js-share-panel-open')) {
				const panel = event.target.querySelector('.share-panel');
				panel.classList.toggle('active');
			}
		};
	});
}
