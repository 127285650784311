const cPopup = () => {
	function pauseVideo(element) {
		element.contentWindow.postMessage('{"event":"command","func":"' + 'stopVideo' + '","args":""}', '*');
	}
	const stopVideo = (element) => {
		const iframe = element.querySelector('iframe');
		if (iframe) {
			pauseVideo(iframe);
			// const iframeSrc = iframe.src;
			// iframe.src = iframeSrc;
		}
	};

	const popupTriggers = document.querySelectorAll('.popup-trigger');
	if (popupTriggers.length > 0) {
		popupTriggers.forEach((popupTrigger) => {
			popupTrigger.addEventListener('click', (e) => {
				e.preventDefault();

				const findPopup = document.querySelector(
					`[data-source="${e.currentTarget.getAttribute('data-target')}"]`,
				);

				if (findPopup) {
					findPopup.classList.add('c-popup--show');
				}
			});
		});
	}

	const popups = document.querySelectorAll('.c-popup');
	if (popups.length > 0) {
		popups.forEach((popup) => {
			const closes = popup.querySelectorAll('.popup-trigger-close');
			if (closes.length > 0) {
				closes.forEach((close) => {
					close.addEventListener('click', (e) => {
						e.preventDefault();
						popup.closest('.c-popup').classList.remove('c-popup--show');
						stopVideo(popup);
					});
				});
			}
			const overlay = popup.querySelector('.c-popup__overlay');
			if (overlay) {
				overlay.addEventListener('click', (e) => {
					e.preventDefault();
					popup.closest('.c-popup').classList.remove('c-popup--show');
					stopVideo(popup);
				});
			}
		});
	}
};

export default cPopup;
