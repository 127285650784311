import Swiper, { Navigation } from 'swiper';

const brands = () => {
	const brandsSliders = document.querySelector('.js-slider-brands');
	if (brandsSliders) {
		// eslint-disable-next-line no-unused-vars
		const swiper = new Swiper('.js-slider-brands', {
			modules: [Navigation],
			slidesPerView: 1,
			spaceBetween: 0,
			autoHeight: false,
			slideToClickedSlide: true,
			navigation: {
				nextEl: '.s-brands__arrow--next',
				prevEl: '.s-brands__arrow--prev',
			},
		});
	}

	const moreCardsBtn = document.querySelector('.show-more-cards');

	if (moreCardsBtn) {
		moreCardsBtn.addEventListener('click', (e) => {
			const target = e.currentTarget;
			const closestContainer = e.currentTarget.closest('.s-brands__small-cards');
			if (closestContainer) {
				const getFirstHidden = closestContainer.querySelector('.s-brands__small-cards-group--hidden');

				if (getFirstHidden) {
					getFirstHidden.classList.remove('s-brands__small-cards-group--hidden');
					const getFirstHiddenUpdate = closestContainer.querySelector('.s-brands__small-cards-group--hidden');
					if (!getFirstHiddenUpdate) {
						target.classList.add('s-brands__small-cards-btn--hidden');
					}
				}
			}
		});
	}
};

export default brands;
