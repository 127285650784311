const wpmlLs = (width) => {
	const wpmlSwitcher = document.querySelector('.floating-language');
	const fullpageNav = document.getElementById('fp-nav');
	if (wpmlSwitcher) {
		if (width > 992) {
			const x = fullpageNav.offsetTop;
			const y = fullpageNav.offsetLeft;
			wpmlSwitcher.style.top = `${x + fullpageNav.offsetHeight / 2 + 20}px`;
			wpmlSwitcher.style.left = `${y - 3}px`;
			wpmlSwitcher.classList.remove('responsive');
		} else {
			wpmlSwitcher.classList.add('responsive');
			wpmlSwitcher.style.top = null;
			wpmlSwitcher.style.left = null;
		}

		wpmlSwitcher.querySelector('ul').setAttribute('id', 'multilanguage-nav');
	}
};

export default wpmlLs;
