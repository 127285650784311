import Swiper, { Navigation } from 'swiper';

/* eslint-disable no-unused-vars */

const partnersSlider = () => {
	const slidersPartners = document.querySelectorAll('.js-slider-partners-logs');

	if (slidersPartners) {
		slidersPartners.forEach((box) => {
			if (box) {
				const arrLeft = box.querySelector('.swiper-button-prev-custom');
				const arrRight = box.querySelector('.swiper-button-next-custom');
				const swiper = new Swiper(box, {
					modules: [Navigation],
					slidesPerView: 3,
					spaceBetween: -1,
					slideToClickedSlide: true,
					centeredSlides: false,
					loop: true,
					preloadImages: false,
					lazy: true,
					effect: 'slide',
					speed: 1000,
					autoplay: true,
					navigation: {
						nextEl: arrRight,
						prevEl: arrLeft,
					},
					breakpoints: {
						992: {
							slidesPerView: 6,
						},
						576: {
							slidesPerView: 4,
						},
					},
				});
			}
		});
	}
};

export default partnersSlider;
