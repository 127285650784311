import Swiper, { Navigation } from 'swiper';

/* eslint-disable no-unused-vars */

const counter = () => {
	const slidersCounter = document.querySelector('.counter__slider');

	if (slidersCounter) {
		const swiper = new Swiper(slidersCounter, {
			modules: [Navigation],
			slidesPerView: 1,
			loop: true,
			navigation: {
				nextEl: '.counter-next',
				prevEl: '.counter-prev',
			},
		});
	}
};

export default counter;
