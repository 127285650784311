import Typewriter from 'typewriter-effect/dist/core';

const hero = () => {
	const heroHtml = document.querySelector('.hero');
	if (heroHtml) {
		const heroText = heroHtml.querySelector('.hero__slogan');
		const slogans = heroHtml.querySelectorAll('.slogan');

		const typewriter = new Typewriter(heroText, {
			loop: true,
			delay: 10,
		});

		slogans.forEach((element) => {
			const text = element.getAttribute('data-string');
			typewriter.typeString(text).pauseFor(500).deleteAll();
		});

		typewriter.start();
	}
};

export default hero;
