const cursor = () => {
	const cursorHtml = document.querySelector('.c-cursor');
	if (cursorHtml) {
		let mouseX = 0;
		let mouseY = 0;

		let cursorX = 0;
		let cursorY = 0;

		const speed = 0.5; // change to increase the ease

		const animate = () => {
			const distX = mouseX - cursorX;
			const distY = mouseY - cursorY;

			cursorX += distX * speed;
			cursorY += distY * speed;

			cursorHtml.style.left = `${cursorX}px`;
			cursorHtml.style.top = `${cursorY}px`;

			requestAnimationFrame(animate);
		};

		animate();

		document.addEventListener('mousemove', (event) => {
			mouseX = event.pageX;
			mouseY = event.pageY;
		});
	}
};

export default cursor;
