const panels = () => {
	const initPanels = () => {
		const singlePanel = document.querySelectorAll('.s-panels__single');
		function hasClass(element, className) {
			return Array.prototype.indexOf.call(element.classList, className) > 0;
		}
		function removeActiveAll() {
			singlePanel.forEach((panel) => {
				panel.classList.remove('open');
				panel.classList.remove('open-active');
			});
		}
		function toggleOpen() {
			if (hasClass(this, 'open-active')) {
				return;
			}
			removeActiveAll();
			this.classList.toggle('open');
		}
		function toggleActive(e) {
			if (e.propertyName.includes('flex') && hasClass(this, 'open')) {
				this.classList.toggle('open-active');
			}
		}

		if (window.matchMedia('(max-width: 768px)').matches) {
			singlePanel.forEach((panel) => {
				panel.addEventListener('click', toggleOpen);
				panel.addEventListener('transitionend', toggleActive);
			});
		} else {
			singlePanel.forEach((panel) => panel.addEventListener('mouseover', toggleOpen));
			singlePanel.forEach((panel) => panel.addEventListener('transitionend', toggleActive));
		}
	};

	initPanels();
	window.addEventListener('resize', initPanels);
};
export default panels;
