import Swiper, { Navigation } from 'swiper';

const potential = () => {
	const potentialSliders = document.querySelector('.js-slider-potential');
	if (potentialSliders) {
		// eslint-disable-next-line no-unused-vars
		const swiper = new Swiper(potentialSliders, {
			modules: [Navigation],
			slidesPerView: 1,
			centeredSlides: true,
			loop: true,
			spaceBetween: 19,
			navigation: {
				nextEl: '.s-potential__arrow--next',
				prevEl: '.s-potential__arrow--prev',
			},
			slideToClickedSlide: true,
			preloadImages: false,
			lazy: true,
			effect: 'slide',
			breakpoints: {
				992: {
					slidesPerView: 3,
				},
				768: {
					slidesPerView: 2,
					spaceBetween: 24,
				},
			},
		});
	}
};

export default potential;
